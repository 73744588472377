import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatIconModule } from "@angular/material";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ExcelService } from '../service/excel.service';
import { LexusJobService } from '../lexus-job/lexus-job.service';
function reportValidator(fg: FormGroup) {
  const jobRole = fg.get('jobRole').value;
  const month = fg.get('month').value;
  const year = fg.get('year').value;
  const area = fg.get('area').value;
  return jobRole && month && year && area
    ? null
    : { range: true };
}
@Component({
  selector: 'app-turn-over-reports',
  templateUrl: './turn-over-reports.component.html',
  styleUrls: ['./turn-over-reports.component.css']
})
export class TurnOverReportsComponent implements OnInit {
  reportMonths = [
    {
      "abbreviation": "Jan",
      "name": "January"
    },
    {
      "abbreviation": "Feb",
      "name": "February"
    },
    {
      "abbreviation": "Mar",
      "name": "March"
    },
    {
      "abbreviation": "Apr",
      "name": "April"
    },
    {
      "abbreviation": "May",
      "name": "May"
    },
    {
      "abbreviation": "Jun",
      "name": "June"
    },
    {
      "abbreviation": "Jul",
      "name": "July"
    },
    {
      "abbreviation": "Aug",
      "name": "August"
    },
    {
      "abbreviation": "Sep",
      "name": "September"
    },
    {
      "abbreviation": "Oct",
      "name": "October"
    },
    {
      "abbreviation": "Nov",
      "name": "November"
    },
    {
      "abbreviation": "Dec",
      "name": "December"
    }
  ]
  reportTitleMonths = [
    {
      "abbreviation": "Jan",
      "name": "January"
    },
    {
      "abbreviation": "Feb",
      "name": "February"
    },
    {
      "abbreviation": "Mar",
      "name": "March"
    },
    {
      "abbreviation": "Apr",
      "name": "April"
    },
    {
      "abbreviation": "May",
      "name": "May"
    },
    {
      "abbreviation": "Jun",
      "name": "June"
    },
    {
      "abbreviation": "Jul",
      "name": "July"
    },
    {
      "abbreviation": "Aug",
      "name": "August"
    },
    {
      "abbreviation": "Sep",
      "name": "September"
    },
    {
      "abbreviation": "Oct",
      "name": "October"
    },
    {
      "abbreviation": "Nov",
      "name": "November"
    },
    {
      "abbreviation": "Dec",
      "name": "December"
    }
  ]
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('ReportPaginator', { read: MatPaginator }) ReportPaginator: MatPaginator;
  searchForm: FormGroup;
  reportArray: any = [];
  reportTempArr: any = [];
  nationalArr: any = [];
  areaReportArr: any = [];
  reportDataSource: MatTableDataSource<any>;
  reportDataColumns: string[] = ["area", "startOfPeriod", "jobTitleChangeAreaNo", "jobTitleChangeAreaPercent", "dlrtoDlrChangeNo", "dlrtoDlrChangePercent","changedAreaNo", "totalAddedAreaNo", "totalAddedAreaPercent", "totalLeavingAreaNo","totalLeavingAreaPercent", "terminatedAreaNo", "terminatedAreaPercent", "endOfPeriod"];
  reportDataColumns1: string[] = ["district", "startOfPeriod", "jobTitleChangeDistNo", "jobTitleChangeDistPercent", "dlrtoDlrChangeno", "dlrtoDlrChangePercent","changedDistNo", "totalAddedDistNo", "totalAddedDistPercent", "totalLeavingDistNo","totalLeavingDistPercent", "terminatedDistNo", "terminatedDistPercent", "endofPeriod"];
  reportDataColumns2: string[] = ["dealerName", "startOfPeriod", "jobTitleChangeDlrNo", "jobTitleChangeDlrPercent", "dlrtoDlrChangeno", "dlrtoDlrChangePercent","totalAddedDlrNo", "totalAddedDlrPercent", "totalLeavingDlrNo","totalLeavingDlrPercent", "terminatedDlrNo", "terminatedDlrPercent", "endofPeriod"];
  tableLoading = true;
  showSpinner = false;
  exportData: any[];
  filterSelected: boolean;
  historyYearList = [{ "name": "2019", "value": "2019" }, { "name": "2020", "value": "2020" }, { "name": "2021", "value": "2021" }, { "name": "2022", "value": "2022" }, { "name": "2023", "value": "2023" }];
  yearList = [{ "name": "2019", "value": "2019" }, { "name": "2020", "value": "2020" }, { "name": "2021", "value": "2021" }, { "name": "2022", "value": "2022" }, { "name": "2023", "value": "2023" },{ "name": "2024", "value": "2024" }];
  currentYear: any;
  jobRoleList = [];
  areaList = [{ "name": "National", "value": "All" }, { "name": "Western", "value": "31 Western" }, { "name": "Eastern", "value": "33 Eastern" }, { "name": "Southern", "value": "34 Southern" }, { "name": "Central", "value": "32 Central" }]
  // areaList = [{ "name": "National", "value": "All" }]
  districtList = [{ "name": "All", "value": "All" }, { "name": "District 1", "value": "District 1" }, { "name": "District 2", "value": "District 2" }, { "name": "District 3", "value": "District 3" }, { "name": "District 4", "value": "District 4" }, { "name": "District 5", "value": "District 5" }, { "name": "District 6", "value": "District 6" }]
  showHistory = false;
  title = '';
  dualJobRole = '';
  selectedIndex = 0;

  constructor(private fb: FormBuilder, private lexusJobService: LexusJobService, private excelService: ExcelService) { }

  ngOnInit() {
    let date = new Date();
    this.currentYear = date.getFullYear();
    let month = date.getMonth() + 1;
    // this.reportMonths.length = month;
    this.reportMonths.length = 12;
    // if (this.currentYear != this.yearList[0].name) {
    //   let len = this.currentYear - Number(this.yearList[0].name)
    //   for (let i = 0; i < len; i++) {
    //     let prevYr = this.yearList[i].name;
    //     let obj = {
    //       "name": (Number(prevYr) + 1).toString(),
    //       "value": (Number(prevYr) + 1).toString()
    //     }
    //     this.yearList.push(obj)
    //   }
    // }
    let newDate = new Date();
    let prevMonthDate = new Date(newDate.setMonth(newDate.getMonth()-1)).getMonth();
    this.searchForm = this.fb.group({
      jobRole: '',
      jobRole1: '',
      year: this.currentYear.toString(),
      // month: this.reportMonths[date.getMonth() - 1].name,
      month: this.reportMonths[prevMonthDate].name,
      area: 'All',
      district: 'All',
      timePeriod: '',
      reportType: 'area',
      type: 'history',
      startDate: '2023-05-01',
      endDate: '2024-04-30'
    }, { validator: reportValidator });
    console.log('this.searchForm', this.searchForm);
    this.lexusJobService.getTurnOverJobCodes().subscribe((response: any) => {
      this.jobRoleList = response.JobCodes.Result;
      this.jobRoleList.push({ "personnel": "Diagnostic Specialist/Service Technician" })
    })
    this.searchForm.controls['district'].disable();
  }
  search(type: string) {
    this.dualJobRole = '';
    this.tableLoading = true;
    this.showSpinner = true;
    this.reportArray = [];
    this.exportData = [];
    this.nationalArr = [];
    this.areaReportArr = [];
    this.reportTempArr = [];
    this.tabClick(0);
    this.reportDataSource = new MatTableDataSource(this.reportArray);
    this.reportDataSource.paginator = this.ReportPaginator;
    this.reportDataSource.sort = this.sort;
    if (type == 'history') {
      this.title = 'Turnover report from 01/01/' + this.searchForm.get('year').value + ' to 12/31/' + this.searchForm.get('year').value
      let period = 'Jan ' + this.searchForm.get('year').value + '- Dec ' + this.searchForm.get('year').value
      console.log('period:::', period)
      this.searchForm.get('timePeriod').setValue(period);
    }
    else {
      let previousMonthNo = this.reportTitleMonths.findIndex(x => x.name === this.searchForm.get('month').value);
      const monthNo = previousMonthNo + 1 == this.reportTitleMonths.length ? 0 : previousMonthNo + 1
      let previousMonth = this.reportTitleMonths[monthNo].name;
      var d = new Date(this.searchForm.get('year').value, monthNo, 0);
      let previousMonFormat = previousMonthNo.toString().length == 2 ? previousMonthNo : '0' + previousMonthNo;
      let monthNoFormat = (monthNo + 1).toString().length == 2 ? (monthNo + 1) : '0' + (monthNo + 1);
      let currentMonFormat = monthNo.toString().length == 2 ? monthNo : '0' + monthNo;
      // this.title = 'Turnover report from ' + previousMonth + ' ' + (Number(this.searchForm.get('year').value) - 1) + ' to ' + this.searchForm.get('month').value + ' ' + this.searchForm.get('year').value
      this.title = 'Turnover report from ' + monthNoFormat + '/01/' + (Number(this.searchForm.get('year').value) - 1) + ' to ' + currentMonFormat + '/' + d.getDate() + '/' + this.searchForm.get('year').value
      let period = previousMonth.substring(0, 3) + ' ' + (Number(this.searchForm.get('year').value) - 1) + '- ' + (this.searchForm.get('month').value).substring(0, 3) + ' ' + this.searchForm.get('year').value
      console.log('period:::', period, monthNo)
      this.searchForm.get('timePeriod').setValue(period);
      this.searchForm.get('type').setValue(monthNo >= 4 ? 'normal' : 'history')
      let endday = new Date(this.searchForm.get('year').value, Number(currentMonFormat), 0).getDate();
      if (monthNoFormat == '01') {
        this.searchForm.get('startDate').setValue((this.searchForm.get('year').value) + '-' + monthNoFormat + '-01')
      } else {
        this.searchForm.get('startDate').setValue((this.searchForm.get('year').value) - 1 + '-' + monthNoFormat + '-01')
      }
      // this.searchForm.get('startDate').setValue((this.searchForm.get('year').value) - 1 + '-' + monthNoFormat + '-01')
      if (currentMonFormat == '00') {
        this.searchForm.get('endDate').setValue(this.searchForm.get('year').value + '-' + '12' + '-' + endday)
      } else {
        this.searchForm.get('endDate').setValue(this.searchForm.get('year').value + '-' + currentMonFormat + '-' + endday)
      }
      // this.searchForm.get('endDate').setValue(this.searchForm.get('year').value + '-' + currentMonFormat + '-' + endday)

    }
    if (this.searchForm.get('area').value == 'All') {
      this.searchForm.get('reportType').setValue('area')
    }
    else if (this.searchForm.get('area').value != 'All' && this.searchForm.get('district').value == 'All') {
      this.searchForm.get('reportType').setValue('district')
    }
    else {
      this.searchForm.get('reportType').setValue('dealer')
    }
    let nationalStartSum = 0;
    let nationalJobTitleChangeAreaNo = 0;
    let nationalJobTitleChangeDealerNo = 0;
    let nationalTerminatedAreaNo = 0
    let nationalTerminatedDealerNo = 0;
    let nationalTotalLeavingAreaNo = 0
    let nationalTotalLeavingDealerNo = 0
    let nationalDlrtoDlrChangeNo = 0
    let nationalTotalAddedAreaNo = 0
    let nationalTotalAddedDealerNo = 0
    let nationalTotalChangedAreaNo = 0;
    let nationalTotalChangedDistNo = 0;
    let nationalEndOfPeriod = 0
    let responseArr = [];
    let certResponseArr = [];
    if (this.searchForm.get('jobRole').value.includes('/')) {
      this.dualJobRole = this.searchForm.get('jobRole').value;
      let jobRoleSpilt = this.searchForm.get('jobRole').value.split('/')
      this.searchForm.get('jobRole').setValue(jobRoleSpilt[0]);
      this.searchForm.get('jobRole1').setValue(jobRoleSpilt[1])
    }
    this.lexusJobService.getTurnOverReport(this.searchForm.value).subscribe((response: any) => {
      this.lexusJobService.getTurnOverCertReport(this.searchForm.value).subscribe((certResponse: any) => {
        responseArr = response.Reports.Result;
        certResponseArr = certResponse.Reports.Result;
        let finalArr = [];
        if (certResponseArr.length > 0) {
          let certCnt = 0;
          let tempArr = []
          if (this.searchForm.get('area').value != 'All' && this.searchForm.get('district').value == 'All') {
          for (let i = 0; i < certResponseArr.length; i++) {
              if ((i < certResponseArr.length - 1) && (certResponseArr[i].district === certResponseArr[i + 1].district)) {
                certCnt += 1;
                tempArr.push(certResponseArr[i]);
              }
              else {
                if (certCnt == 4) {
                  certCnt = 0;
                  finalArr = [...finalArr,...tempArr]
                }
                else {
                  let distAlone = certResponseArr.filter(element => {
                      return element.district == certResponseArr[i].district
                  });
                  console.log('distAlone::', distAlone)
                  if (distAlone[0] && (distAlone[0].certStatus != 'Not Certified')) {
                      distAlone.splice(0, 0, {
                        "district": distAlone[0].district,
                        "startOfPeriod": 0,
                        "jobTitleChangeDistNo": 0,
                        "dlrtoDlrChangeNo": 0,
                        "changedDistNo": 0,
                        "totalLeavingDistNo": 0,
                        "totalAddedDistNo": 0,
                        "terminatedDistNo": 0,
                        "endOfPeriod": 0,
                        "certStatus": "Not Certified"
                      });
                  }
                  else if (distAlone[0] == undefined) {
                      distAlone.splice(0, 0, {
                        "district": distAlone[0].district,
                        "startOfPeriod": 0,
                        "jobTitleChangeDistNo": 0,
                        "dlrtoDlrChangeNo": 0,
                        "changedDistNo": 0,
                        "totalLeavingDistNo": 0,
                        "totalAddedDistNo": 0,
                        "terminatedDistNo": 0,
                        "endOfPeriod": 0,
                        "certStatus": "Not Certified"
                      });
                  }
                  // console.log(distAlone[1].certStatus)
                  if (distAlone[1] && (distAlone[1].certStatus != 'Foundation')) {
                      distAlone.splice(1, 0, {
                        "district": distAlone[0].district,
                        "startOfPeriod": 0,
                        "jobTitleChangeDistNo": 0,
                        "dlrtoDlrChangeNo": 0,
                        "changedDistNo": 0,
                        "totalLeavingDistNo": 0,
                        "totalAddedDistNo": 0,
                        "terminatedDistNo": 0,
                        "endOfPeriod": 0,
                        "certStatus": "Foundation"
                      });
                  }
                  else if (distAlone[1] == undefined) {
                      distAlone.splice(1, 0, {
                        "district": distAlone[0].district,
                        "startOfPeriod": 0,
                        "jobTitleChangeDistNo": 0,
                        "dlrtoDlrChangeNo": 0,
                        "changedDistNo": 0,
                        "totalLeavingDistNo": 0,
                        "totalAddedDistNo": 0,
                        "terminatedDistNo": 0,
                        "endOfPeriod": 0,
                        "certStatus": "Foundation"
                      });
                  }
                  if (distAlone[2] && (distAlone[2].certStatus != 'Senior')) {
                      distAlone.splice(2, 0, {
                        "district": distAlone[0].district,
                        "startOfPeriod": 0,
                        "jobTitleChangeDistNo": 0,
                        "dlrtoDlrChangeNo": 0,
                        "changedDistNo": 0,
                        "totalLeavingDistNo": 0,
                        "totalAddedDistNo": 0,
                        "terminatedDistNo": 0,
                        "endOfPeriod": 0,
                        "certStatus": "Senior"
                      });
                  }
                  else if (distAlone[2] == undefined) {
                      distAlone.splice(2, 0, {
                        "district": distAlone[0].district,
                        "startOfPeriod": 0,
                        "jobTitleChangeDistNo": 0,
                        "dlrtoDlrChangeNo": 0,
                        "changedDistNo": 0,
                        "totalLeavingDistNo": 0,
                        "totalAddedDistNo": 0,
                        "terminatedDistNo": 0,
                        "endOfPeriod": 0,
                        "certStatus": "Senior"
                      });
                  }
                  // console.log(distAlone[3].certStatus)
                  if (distAlone[3] && (distAlone[3].certStatus != 'Master')) {
                      distAlone.splice(3, 0, {
                        "district": distAlone[0].district,
                        "startOfPeriod": 0,
                        "jobTitleChangeDistNo": 0,
                        "dlrtoDlrChangeNo": 0,
                        "changedDistNo": 0,
                        "totalLeavingDistNo": 0,
                        "totalAddedDistNo": 0,
                        "terminatedDistNo": 0,
                        "endOfPeriod": 0,
                        "certStatus": "Master"
                      });
                  }
                  else if (distAlone[3] == undefined) {
                      distAlone.splice(3, 0, {
                        "district": distAlone[0].district,
                        "startOfPeriod": 0,
                        "jobTitleChangeDistNo": 0,
                        "dlrtoDlrChangeNo": 0,
                        "changedDistNo": 0,
                        "totalLeavingDistNo": 0,
                        "totalAddedDistNo": 0,
                        "terminatedDistNo": 0,
                        "endOfPeriod": 0,
                        "certStatus": "Master"
                      });
                  }
                  certCnt = 0;
                  finalArr = [...finalArr, ...distAlone];
                }
              }
            }
          }
          else if (this.searchForm.get('area').value == 'All') {
            for (let i = 0; i < certResponseArr.length; i++) {
                if ((i < certResponseArr.length - 1) && (certResponseArr[i].area === certResponseArr[i + 1].area)) {
                  certCnt += 1;
                  tempArr.push(certResponseArr[i]);
                }
                else {
                  if (certCnt == 4) {
                    certCnt = 0;
                    finalArr = [...finalArr,...tempArr]
                  }
                  else {
                    let distAlone = certResponseArr.filter(element => {
                        return element.area == certResponseArr[i].area
                    });
                    console.log('distAlone::', distAlone)
                    if (distAlone[0] && (distAlone[0].certStatus != 'Not Certified')) {
                        distAlone.splice(0, 0, {
                          "area": distAlone[0].area,
                          "startOfPeriod": 0,
                          "jobTitleChangeAreaNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "changedAreaNo": 0,
                          "totalLeavingAreaNo": 0,
                          "totalAddedAreaNo": 0,
                          "terminatedAreaNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Not Certified"
                        });
                    }
                    else if (distAlone[0] == undefined) {
                        distAlone.splice(0, 0, {
                          "area": distAlone[0].area,
                          "startOfPeriod": 0,
                          "jobTitleChangeAreaNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "changedAreaNo": 0,
                          "totalLeavingAreaNo": 0,
                          "totalAddedAreaNo": 0,
                          "terminatedAreaNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Not Certified"
                        });
                    }
                    // console.log(distAlone[1].certStatus)
                    if (distAlone[1] && (distAlone[1].certStatus != 'Foundation')) {
                        distAlone.splice(1, 0, {
                          "area": distAlone[0].area,
                          "startOfPeriod": 0,
                          "jobTitleChangeAreaNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "changedAreaNo": 0,
                          "totalLeavingAreaNo": 0,
                          "totalAddedAreaNo": 0,
                          "terminatedAreaNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Foundation"
                        });
                    }
                    else if (distAlone[1] == undefined) {
                        distAlone.splice(1, 0, {
                          "area": distAlone[0].area,
                          "startOfPeriod": 0,
                          "jobTitleChangeAreaNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "changedAreaNo": 0,
                          "totalLeavingAreaNo": 0,
                          "totalAddedAreaNo": 0,
                          "terminatedAreaNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Foundation"
                        });
                    }
                    if (distAlone[2] && (distAlone[2].certStatus != 'Senior')) {
                        distAlone.splice(2, 0, {
                          "area": distAlone[0].area,
                          "startOfPeriod": 0,
                          "jobTitleChangeAreaNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "changedAreaNo": 0,
                          "totalLeavingAreaNo": 0,
                          "totalAddedAreaNo": 0,
                          "terminatedAreaNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Senior"
                        });
                    }
                    else if (distAlone[2] == undefined) {
                        distAlone.splice(2, 0, {
                          "area": distAlone[0].area,
                          "startOfPeriod": 0,
                          "jobTitleChangeAreaNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "changedAreaNo": 0,
                          "totalLeavingAreaNo": 0,
                          "totalAddedAreaNo": 0,
                          "terminatedAreaNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Senior"
                        });
                    }
                    // console.log(distAlone[3].certStatus)
                    if (distAlone[3] && (distAlone[3].certStatus != 'Master')) {
                        distAlone.splice(3, 0, {
                          "area": distAlone[0].area,
                          "startOfPeriod": 0,
                          "jobTitleChangeAreaNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "changedAreaNo": 0,
                          "totalLeavingAreaNo": 0,
                          "totalAddedAreaNo": 0,
                          "terminatedAreaNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Master"
                        });
                    }
                    else if (distAlone[3] == undefined) {
                        distAlone.splice(3, 0, {
                          "area": distAlone[0].area,
                          "startOfPeriod": 0,
                          "jobTitleChangeAreaNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "changedAreaNo": 0,
                          "totalLeavingAreaNo": 0,
                          "totalAddedAreaNo": 0,
                          "terminatedAreaNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Master"
                        });
                    }
                    certCnt = 0;
                    finalArr = [...finalArr, ...distAlone];
                  }
                }
              }
          }
          else {
            for (let i = 0; i < certResponseArr.length; i++) {
                if ((i < certResponseArr.length - 1) && (certResponseArr[i].dealerName === certResponseArr[i + 1].dealerName)) {
                  certCnt += 1;
                  tempArr.push(certResponseArr[i]);
                }
                else {
                  if (certCnt == 4) {
                    certCnt = 0;
                    finalArr = [...finalArr,...tempArr]
                  }
                  else {
                    let distAlone = certResponseArr.filter(element => {
                        return element.dealerName == certResponseArr[i].dealerName
                    });
                    console.log('distAlone::', distAlone)
                    if (distAlone[0] && (distAlone[0].certStatus != 'Not Certified')) {
                        distAlone.splice(0, 0, {
                          "dealerName": distAlone[0].dealerName,
                          "startOfPeriod": 0,
                          "jobTitleChangeDlrNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "totalLeavingDlrNo": 0,
                          "totalAddedDlrNo": 0,
                          "terminatedDlrNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Not Certified"
                        });
                    }
                    else if (distAlone[0] == undefined) {
                        distAlone.splice(0, 0, {
                          "dealerName": distAlone[0].dealerName,
                          "startOfPeriod": 0,
                          "jobTitleChangeDlrNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "totalLeavingDlrNo": 0,
                          "totalAddedDlrNo": 0,
                          "terminatedDlrNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Not Certified"
                        });
                    }
                    // console.log(distAlone[1].certStatus)
                    if (distAlone[1] && (distAlone[1].certStatus != 'Foundation')) {
                        distAlone.splice(1, 0, {
                          "dealerName": distAlone[0].dealerName,
                          "startOfPeriod": 0,
                          "jobTitleChangeDlrNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "totalLeavingDlrNo": 0,
                          "totalAddedDlrNo": 0,
                          "terminatedDlrNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Foundation"
                        });
                    }
                    else if (distAlone[1] == undefined) {
                        distAlone.splice(1, 0, {
                         "dealerName": distAlone[0].dealerName,
                          "startOfPeriod": 0,
                          "jobTitleChangeDlrNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "totalLeavingDlrNo": 0,
                          "totalAddedDlrNo": 0,
                          "terminatedDlrNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Foundation"
                        });
                    }
                    if (distAlone[2] && (distAlone[2].certStatus != 'Senior')) {
                        distAlone.splice(2, 0, {
                          "dealerName": distAlone[0].dealerName,
                          "startOfPeriod": 0,
                          "jobTitleChangeDlrNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "totalLeavingDlrNo": 0,
                          "totalAddedDlrNo": 0,
                          "terminatedDlrNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Senior"
                        });
                    }
                    else if (distAlone[2] == undefined) {
                        distAlone.splice(2, 0, {
                          "dealerName": distAlone[0].dealerName,
                          "startOfPeriod": 0,
                          "jobTitleChangeDlrNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "totalLeavingDlrNo": 0,
                          "totalAddedDlrNo": 0,
                          "terminatedDlrNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Senior"
                        });
                    }
                    // console.log(distAlone[3].certStatus)
                    if (distAlone[3] && (distAlone[3].certStatus != 'Master')) {
                        distAlone.splice(3, 0, {
                          "dealerName": distAlone[0].dealerName,
                          "startOfPeriod": 0,
                          "jobTitleChangeDlrNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "totalLeavingDlrNo": 0,
                          "totalAddedDlrNo": 0,
                          "terminatedDlrNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Master"
                        });
                    }
                    else if (distAlone[3] == undefined) {
                        distAlone.splice(3, 0, {
                         "dealerName": distAlone[0].dealerName,
                          "startOfPeriod": 0,
                          "jobTitleChangeDlrNo": 0,
                          "dlrtoDlrChangeNo": 0,
                          "totalLeavingDlrNo": 0,
                          "totalAddedDlrNo": 0,
                          "terminatedDlrNo": 0,
                          "endOfPeriod": 0,
                          "certStatus": "Master"
                        });
                    }
                    certCnt = 0;
                    finalArr = [...finalArr, ...distAlone];
                  }
                }
              }
          }
        }
        certResponseArr = [...finalArr]
        if (responseArr.length > 0) {
          if (this.searchForm.get('area').value == 'All') {
            this.reportArray = [];
            this.exportData = [];
            for (let i = 0; i < responseArr.length; i++) {
              this.reportArray.push(responseArr[i]);
              this.exportData.push(responseArr[i]);

              console.log('reportArray', this.reportArray)
              // this.reportArray[i].totalLeavingAreaNo = Number(this.reportArray[i].totalLeavingAreaNo) - Number(this.reportArray[i].terminatedAreaNo);
              nationalStartSum += Number(this.reportArray[i].startOfPeriod);
              nationalJobTitleChangeAreaNo += Number(this.reportArray[i].jobTitleChangeAreaNo);
              nationalJobTitleChangeDealerNo += Number(this.reportArray[i].jobTitleChangeDealerNo);
              nationalTerminatedAreaNo += Number(this.reportArray[i].terminatedAreaNo);
              nationalTerminatedDealerNo += Number(this.reportArray[i].terminatedDealerNo);
              nationalTotalLeavingAreaNo += Number(this.reportArray[i].totalLeavingAreaNo);
              nationalTotalLeavingDealerNo += Number(this.reportArray[i].totalLeavingDealerNo);
              nationalDlrtoDlrChangeNo += Number(this.reportArray[i].dlrtoDlrChangeNo);
              nationalTotalAddedAreaNo += Number(this.reportArray[i].totalAddedAreaNo);
              nationalTotalAddedDealerNo += Number(this.reportArray[i].totalAddedDealerNo);
              nationalTotalChangedAreaNo += Number(this.reportArray[i].changedAreaNo);
              nationalEndOfPeriod += Number(this.reportArray[i].endOfPeriod);
              if (this.reportArray[i].startOfPeriod != 0) {
                this.reportArray[i].dlrtoDlrChangePercent = (Math.round(Number(this.reportArray[i].dlrtoDlrChangeNo) * 100) / Number(this.reportArray[i].startOfPeriod)).toFixed(1)
                this.reportArray[i].jobTitleChangeAreaPercent = (Math.round(Number(this.reportArray[i].jobTitleChangeAreaNo) * 100) / Number(this.reportArray[i].startOfPeriod)).toFixed(1)
                this.reportArray[i].totalAddedAreaPercent = (Math.round(Number(this.reportArray[i].totalAddedAreaNo) * 100) / Number(this.reportArray[i].startOfPeriod)).toFixed(1)
                this.reportArray[i].totalLeavingAreaPercent = (Math.round(Number(this.reportArray[i].totalLeavingAreaNo) * 100) / Number(this.reportArray[i].startOfPeriod)).toFixed(1)
                this.reportArray[i].terminatedAreaPercent = (Math.round(Number(this.reportArray[i].terminatedAreaNo) * 100) / Number(this.reportArray[i].startOfPeriod)).toFixed(1)
              }
              else {
                this.reportArray[i].dlrtoDlrChangePercent = 0;
                this.reportArray[i].jobTitleChangeAreaPercent = 0;
                this.reportArray[i].totalAddedAreaPercent = 0;
                this.reportArray[i].totalLeavingAreaPercent = 0;
                this.reportArray[i].terminatedAreaPercent = 0;
              }

            }
            let nationalReport = {
              "timePeriod": this.reportArray[0].timePeriod,
              "jobRole": this.reportArray[0].jobRole,
              "area": "National",
              "startOfPeriod": nationalStartSum,
              "jobTitleChangeAreaNo": nationalJobTitleChangeAreaNo,
              "jobTitleChangeAreaPercent": (Math.round(nationalJobTitleChangeAreaNo * 100) / nationalStartSum).toFixed(1),
              "jobTitleChangeDealerNo": nationalJobTitleChangeDealerNo,
              "jobTitleChangeDealerPercent": (Math.round(nationalJobTitleChangeDealerNo * 100) / nationalStartSum).toFixed(1),
              "terminatedAreaNo": nationalTerminatedAreaNo,
              "terminatedAreaPercent": (Math.round(nationalTerminatedAreaNo * 100) / nationalStartSum).toFixed(1),
              "terminatedDealerNo": nationalTerminatedDealerNo,
              "terminatedDealerPercent": (Math.round(nationalTerminatedDealerNo * 100) / nationalStartSum).toFixed(1),
              "totalLeavingAreaNo": nationalTotalLeavingAreaNo,
              "totalLeavingAreaPercent": (Math.round(nationalTotalLeavingAreaNo * 100) / nationalStartSum).toFixed(1),
              "totalLeavingDealerNo": nationalTotalLeavingDealerNo,
              "totalLeavingDealerPercent": (Math.round(nationalTotalLeavingDealerNo * 100) / nationalStartSum).toFixed(1),
              "dlrtoDlrChangeNo": nationalDlrtoDlrChangeNo,
              "dlrtoDlrChangePercent": (Math.round(nationalDlrtoDlrChangeNo * 100) / nationalStartSum).toFixed(1),
              "totalAddedAreaNo": nationalTotalAddedAreaNo,
              "totalAddedAreaPercent": (Math.round(nationalTotalAddedAreaNo * 100) / nationalStartSum).toFixed(1),
              "totalAddedDealerNo": nationalTotalAddedDealerNo,
              "totalAddedDealerPercent": (Math.round(nationalTotalAddedDealerNo * 100) / nationalStartSum).toFixed(1),
              "endOfPeriod": nationalEndOfPeriod,
              "changedAreaNo": nationalTotalChangedAreaNo,
              "percentChange": (Math.round(nationalEndOfPeriod * 100) / nationalStartSum).toFixed(1)
            };
            console.log('nationalReport:::', nationalReport)
            this.reportArray.push(nationalReport);
            this.nationalArr.push(nationalReport);
            this.reportTempArr = [];
            this.reportArray.forEach(reportelement => {
              this.reportTempArr.push(reportelement);
              certResponseArr.forEach(certelement => {
                if (reportelement.area == certelement.area) {
                  const elementTemp = certelement;
                  const a = elementTemp.area
                  elementTemp.area = certelement.certStatus;
                  elementTemp.certStatus = a;
                  if (elementTemp.startOfPeriod != 0) {
                    elementTemp.dlrtoDlrChangePercent = (Math.round(Number(elementTemp.dlrtoDlrChangeNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                    elementTemp.jobTitleChangeAreaPercent = (Math.round(Number(elementTemp.jobTitleChangeAreaNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                    elementTemp.totalAddedAreaPercent = (Math.round(Number(elementTemp.totalAddedAreaNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                    elementTemp.terminatedAreaPercent = (Math.round(Number(elementTemp.terminatedAreaNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                    elementTemp.totalLeavingAreaPercent = (Math.round(Number(elementTemp.totalLeavingAreaNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                  }
                  else {
                    elementTemp.dlrtoDlrChangePercent = 0;
                    elementTemp.jobTitleChangeAreaPercent = 0;
                    elementTemp.totalAddedAreaPercent = 0;
                    elementTemp.terminatedAreaPercent = 0;
                    elementTemp.totalLeavingAreaPercent = 0;
                  }
                  this.reportTempArr.push(elementTemp)
                }
              });
            })

            const groupedByCertStatus = {};
            this.reportTempArr.forEach((element) => {
              if (element.certStatus && element.area) {
                const certStatus = element.area;
                groupedByCertStatus[certStatus] = groupedByCertStatus[certStatus] || [];
                groupedByCertStatus[certStatus].push(element);
              }
            });
            let resultArray = [];
            nationalStartSum = 0;
            nationalJobTitleChangeAreaNo = 0;
            nationalJobTitleChangeDealerNo = 0;
            nationalTerminatedAreaNo = 0;
            nationalTerminatedDealerNo = 0;
            nationalTotalLeavingAreaNo = 0;
            nationalTotalLeavingDealerNo = 0;
            nationalDlrtoDlrChangeNo = 0;
            nationalTotalAddedAreaNo = 0;
            nationalTotalAddedDealerNo = 0;
            nationalTotalChangedAreaNo =0;
            nationalTotalChangedDistNo = 0;
            nationalEndOfPeriod = 0;
            resultArray = Object.values(groupedByCertStatus);
            let certStatusReport = ''
            for (let i = 0; i < resultArray.length; i++) {
              resultArray[i].filter(element => {
                certStatusReport = element.area
                nationalStartSum += Number(element.startOfPeriod);
                nationalJobTitleChangeAreaNo += Number(element.jobTitleChangeAreaNo);
                nationalJobTitleChangeDealerNo += Number(element.jobTitleChangeDealerNo);
                nationalTerminatedAreaNo += Number(element.terminatedAreaNo);
                nationalTerminatedDealerNo += Number(element.terminatedDealerNo);
                nationalTotalLeavingAreaNo += Number(element.totalLeavingAreaNo);
                nationalTotalLeavingDealerNo += Number(element.totalLeavingDealerNo);
                nationalDlrtoDlrChangeNo += Number(element.dlrtoDlrChangeNo);
                nationalTotalAddedAreaNo += Number(element.totalAddedAreaNo);
                nationalTotalAddedDealerNo += Number(element.totalAddedDealerNo);
                nationalTotalChangedAreaNo += Number(element.changedAreaNo);
                nationalEndOfPeriod += Number(element.endOfPeriod);
              })
              let nationalReport = {
                "timePeriod": this.reportArray[0].timePeriod,
                "jobRole": this.reportArray[0].jobRole,
                "area": certStatusReport,
                "startOfPeriod": nationalStartSum,
                "jobTitleChangeAreaNo": nationalJobTitleChangeAreaNo,
                "jobTitleChangeAreaPercent": (Math.round(nationalJobTitleChangeAreaNo * 100) / nationalStartSum).toFixed(1),
                "jobTitleChangeDealerNo": nationalJobTitleChangeDealerNo,
                "jobTitleChangeDealerPercent": (Math.round(nationalJobTitleChangeDealerNo * 100) / nationalStartSum).toFixed(1),
                "terminatedAreaNo": nationalTerminatedAreaNo,
                "terminatedAreaPercent": (Math.round(nationalTerminatedAreaNo * 100) / nationalStartSum).toFixed(1),
                "terminatedDealerNo": nationalTerminatedDealerNo,
                "terminatedDealerPercent": (Math.round(nationalTerminatedDealerNo * 100) / nationalStartSum).toFixed(1),
                "totalLeavingAreaNo": nationalTotalLeavingAreaNo,
                "totalLeavingAreaPercent": (Math.round(nationalTotalLeavingAreaNo * 100) / nationalStartSum).toFixed(1),
                "totalLeavingDealerNo": nationalTotalLeavingDealerNo,
                "totalLeavingDealerPercent": (Math.round(nationalTotalLeavingDealerNo * 100) / nationalStartSum).toFixed(1),
                "dlrtoDlrChangeNo": nationalDlrtoDlrChangeNo,
                "dlrtoDlrChangePercent": (Math.round(nationalDlrtoDlrChangeNo * 100) / nationalStartSum).toFixed(1),
                "totalAddedAreaNo": nationalTotalAddedAreaNo,
                "totalAddedAreaPercent": (Math.round(nationalTotalAddedAreaNo * 100) / nationalStartSum).toFixed(1),
                "totalAddedDealerNo": nationalTotalAddedDealerNo,
                "totalAddedDealerPercent": (Math.round(nationalTotalAddedDealerNo * 100) / nationalStartSum).toFixed(1),
                "endOfPeriod": nationalEndOfPeriod,
                "changedAreaNo": nationalTotalChangedAreaNo,
                "percentChange": (Math.round(nationalEndOfPeriod * 100) / nationalStartSum).toFixed(1)
              };
              // this.reportTempArr.push(nationalReport);
              console.log('nationalReport', nationalReport, this.nationalArr)
              this.nationalArr.push(nationalReport);
              nationalStartSum = 0;
              nationalJobTitleChangeAreaNo = 0;
              nationalJobTitleChangeDealerNo = 0;
              nationalTerminatedAreaNo = 0;
              nationalTerminatedDealerNo = 0;
              nationalTotalLeavingAreaNo = 0;
              nationalTotalLeavingDealerNo = 0;
              nationalDlrtoDlrChangeNo = 0;
              nationalTotalAddedAreaNo = 0;
              nationalTotalAddedDealerNo = 0;
              nationalTotalChangedAreaNo = 0;
              nationalTotalChangedDistNo = 0;
              nationalEndOfPeriod = 0;

            }
            // this.reportArray = this.reportTempArr;
            // this.reportArray = [...this.reportArray];
            this.exportData = this.reportArray;
            this.areaReportArr = this.reportArray;
            this.tableLoading = false;
            this.showSpinner = false;
            if (this.dualJobRole.length > 0) {
              this.searchForm.get('jobRole').setValue(this.dualJobRole);
              this.searchForm.get('jobRole1').setValue('');
              this.dualJobRole = '';
            }
            this.reportDataSource = new MatTableDataSource(this.reportArray);
            setTimeout(() => {
              this.reportDataSource.sort = this.sort;
              this.reportDataSource.paginator = this.ReportPaginator;
            });
          }
          else if (this.searchForm.get('area').value != 'All' && this.searchForm.get('district').value == 'All') {
            this.reportArray = [];
            this.exportData = [];
            for (let i = 0; i < responseArr.length; i++) {
              this.reportArray.push(responseArr[i]);
              this.exportData.push(responseArr[i]);
              // this.reportArray[i].totalLeavingDistNo = Number(this.reportArray[i].totalLeavingDistNo) - Number(this.reportArray[i].terminatedDistNo);
              nationalStartSum += Number(this.reportArray[i].startOfPeriod);
              nationalJobTitleChangeAreaNo += Number(this.reportArray[i].jobTitleChangeDistNo);
              nationalJobTitleChangeDealerNo += Number(this.reportArray[i].jobTitleChangeDealerNo);
              nationalTerminatedAreaNo += Number(this.reportArray[i].terminatedDistNo);
              nationalTerminatedDealerNo += Number(this.reportArray[i].terminatedDealerNo);
              nationalTotalLeavingAreaNo += Number(this.reportArray[i].totalLeavingDistNo);
              nationalTotalLeavingDealerNo += Number(this.reportArray[i].totalLeavingDealerNo);
              nationalDlrtoDlrChangeNo += Number(this.reportArray[i].dlrtoDlrChangeNo);
              nationalTotalAddedAreaNo += Number(this.reportArray[i].totalAddedDistNo);
              nationalTotalAddedDealerNo += Number(this.reportArray[i].totalAddedDealerNo);
              nationalTotalChangedDistNo += Number(this.reportArray[i].changedDistNo);
              nationalEndOfPeriod += Number(this.reportArray[i].endOfPeriod);
              if (this.reportArray[i].startOfPeriod != 0) {
                this.reportArray[i].dlrtoDlrChangePercent = (Math.round(Number(this.reportArray[i].dlrtoDlrChangeNo) * 100) / this.reportArray[i].startOfPeriod).toFixed(1)
                this.reportArray[i].jobTitleChangeDistPercent = (Math.round(Number(this.reportArray[i].jobTitleChangeDistNo) * 100) / this.reportArray[i].startOfPeriod).toFixed(1)
                this.reportArray[i].totalAddedDistPercent = (Math.round(Number(this.reportArray[i].totalAddedDistNo) * 100) / this.reportArray[i].startOfPeriod).toFixed(1)
                this.reportArray[i].totalLeavingDistPercent = (Math.round(Number(this.reportArray[i].totalLeavingDistNo) * 100) / this.reportArray[i].startOfPeriod).toFixed(1)
                this.reportArray[i].terminatedDistPercent = (Math.round(Number(this.reportArray[i].terminatedDistNo) * 100) / this.reportArray[i].startOfPeriod).toFixed(1)
              }
              else {
                this.reportArray[i].dlrtoDlrChangePercent = 0;
                this.reportArray[i].jobTitleChangeDistPercent = 0;
                this.reportArray[i].totalAddedDistPercent = 0;
                this.reportArray[i].terminatedDistPercent = 0;
                this.reportArray[i].totalLeavingDistPercent = 0;
              }
            }
            let nationalReport = {
              "timePeriod": this.reportArray[0].timePeriod,
              "jobRole": this.reportArray[0].jobRole,
              "district": this.searchForm.get('area').value,
              "startOfPeriod": nationalStartSum,
              "jobTitleChangeDistNo": nationalJobTitleChangeAreaNo,
              "jobTitleChangeDistPercent": (Math.round(nationalJobTitleChangeAreaNo * 100) / nationalStartSum).toFixed(1),
              "jobTitleChangeDealerNo": nationalJobTitleChangeDealerNo,
              "jobTitleChangeDealerPercent": (Math.round(nationalJobTitleChangeDealerNo * 100) / nationalStartSum).toFixed(1),
              "terminatedDistNo": nationalTerminatedAreaNo,
              "terminatedDistPercent": (Math.round(nationalTerminatedAreaNo * 100) / nationalStartSum).toFixed(1),
              "terminatedDealerNo": nationalTerminatedDealerNo,
              "terminatedDealerPercent": (Math.round(nationalTerminatedDealerNo * 100) / nationalStartSum).toFixed(1),
              "totalLeavingDistNo": nationalTotalLeavingAreaNo,
              "totalLeavingDistPercent": (Math.round(nationalTotalLeavingAreaNo * 100) / nationalStartSum).toFixed(1),
              "totalLeavingDealerNo": nationalTotalLeavingDealerNo,
              "totalLeavingDealerPercent": (Math.round(nationalTotalLeavingDealerNo * 100) / nationalStartSum).toFixed(1),
              "dlrtoDlrChangeNo": nationalDlrtoDlrChangeNo,
              "dlrtoDlrChangePercent": (Math.round(nationalDlrtoDlrChangeNo * 100) / nationalStartSum).toFixed(1),
              "totalAddedDistNo": nationalTotalAddedAreaNo,
              "totalAddedDistPercent": (Math.round(nationalTotalAddedAreaNo * 100) / nationalStartSum).toFixed(1),
              "totalAddedDealerNo": nationalTotalAddedDealerNo,
              "totalAddedDealerPercent": (Math.round(nationalTotalAddedDealerNo * 100) / nationalStartSum).toFixed(1),
              "endOfPeriod": nationalEndOfPeriod,
              "changedDistNo": nationalTotalChangedDistNo,
              "percentChange": (Math.round(nationalEndOfPeriod * 100) / nationalStartSum).toFixed(1)
            };
            console.log('nationalReport:::', nationalReport)
            this.reportArray.push(nationalReport);
            this.nationalArr.push(nationalReport);
            this.reportTempArr = [];
            this.reportArray.forEach(reportelement => {
              this.reportTempArr.push(reportelement);
              certResponseArr.forEach(certelement => {
                if (reportelement.district == certelement.district) {
                  const elementTemp = certelement;
                  const a = elementTemp.district
                  elementTemp.district = certelement.certStatus;
                  elementTemp.certStatus = a;
                  if (elementTemp.startOfPeriod != 0) {
                    elementTemp.dlrtoDlrChangePercent = (Math.round(Number(elementTemp.dlrtoDlrChangeNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                    elementTemp.jobTitleChangeDistPercent = (Math.round(Number(elementTemp.jobTitleChangeDistNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                    elementTemp.totalAddedDistPercent = (Math.round(Number(elementTemp.totalAddedDistNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                    elementTemp.terminatedDistPercent = (Math.round(Number(elementTemp.terminatedDistNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                    elementTemp.totalLeavingDistPercent = (Math.round(Number(elementTemp.totalLeavingDistNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                  }
                  else {
                    elementTemp.dlrtoDlrChangePercent = 0;
                    elementTemp.jobTitleChangeDistPercent = 0;
                    elementTemp.totalAddedDistPercent = 0;
                    elementTemp.terminatedDistPercent = 0;
                    elementTemp.totalLeavingDistPercent = 0;
                  }
                  this.reportTempArr.push(elementTemp)
                }
              });
            })
            console.log('reportTempArr:::', this.reportTempArr)
            const groupedByCertStatus = {};
            const groupedBydist = {};
            this.reportTempArr.forEach((element) => {
              if (element.certStatus && element.district) {
                const certStatus = element.district;
                groupedByCertStatus[certStatus] = groupedByCertStatus[certStatus] || [];
                groupedByCertStatus[certStatus].push(element);
              }
            });
            let resultArray = [];
            resultArray = Object.values(groupedByCertStatus);
            this.reportTempArr.forEach((element) => {
              if (element.certStatus && element.district) {
                const certStatus = element.certStatus;
                groupedBydist[certStatus] = groupedBydist[certStatus] || [];
                groupedBydist[certStatus].push(element);
              }
            });
            console.log('groupedBydist:::', groupedBydist)

            let certStatusReport = ''
            for (let i = 0; i < resultArray.length; i++) {
              resultArray[i].filter(element => {
                certStatusReport = element.district
                nationalStartSum += Number(element.startOfPeriod);
                nationalJobTitleChangeAreaNo += Number(element.jobTitleChangeDistNo);
                nationalJobTitleChangeDealerNo += Number(element.jobTitleChangeDealerNo);
                nationalTerminatedAreaNo += Number(element.terminatedDistNo);
                nationalTerminatedDealerNo += Number(element.terminatedDealerNo);
                nationalTotalLeavingAreaNo += Number(element.totalLeavingDistNo);
                nationalTotalLeavingDealerNo += Number(element.totalLeavingDealerNo);
                nationalDlrtoDlrChangeNo += Number(element.dlrtoDlrChangeNo);
                nationalTotalAddedAreaNo += Number(element.totalAddedDistNo);
                nationalTotalAddedDealerNo += Number(element.totalAddedDealerNo);
                nationalTotalChangedDistNo += Number(element.changedDistNo);
                nationalEndOfPeriod += Number(element.endOfPeriod);
              })
              let nationalReport = {
                "timePeriod": this.reportArray[0].timePeriod,
                "jobRole": this.reportArray[0].jobRole,
                "district": certStatusReport,
                "startOfPeriod": nationalStartSum,
                "jobTitleChangeDistNo": nationalJobTitleChangeAreaNo,
                "jobTitleChangeDistPercent": (Math.round(nationalJobTitleChangeAreaNo * 100) / nationalStartSum).toFixed(1),
                "jobTitleChangeDealerNo": nationalJobTitleChangeDealerNo,
                "jobTitleChangeDealerPercent": (Math.round(nationalJobTitleChangeDealerNo * 100) / nationalStartSum).toFixed(1),
                "terminatedDistNo": nationalTerminatedAreaNo,
                "terminatedDistPercent": (Math.round(nationalTerminatedAreaNo * 100) / nationalStartSum).toFixed(1),
                "terminatedDealerNo": nationalTerminatedDealerNo,
                "terminatedDealerPercent": (Math.round(nationalTerminatedDealerNo * 100) / nationalStartSum).toFixed(1),
                "totalLeavingDistNo": nationalTotalLeavingAreaNo,
                "totalLeavingDistPercent": (Math.round(nationalTotalLeavingAreaNo * 100) / nationalStartSum).toFixed(1),
                "totalLeavingDealerNo": nationalTotalLeavingDealerNo,
                "totalLeavingDealerPercent": (Math.round(nationalTotalLeavingDealerNo * 100) / nationalStartSum).toFixed(1),
                "dlrtoDlrChangeNo": nationalDlrtoDlrChangeNo,
                "dlrtoDlrChangePercent": (Math.round(nationalDlrtoDlrChangeNo * 100) / nationalStartSum).toFixed(1),
                "totalAddedDistNo": nationalTotalAddedAreaNo,
                "totalAddedDistPercent": (Math.round(nationalTotalAddedAreaNo * 100) / nationalStartSum).toFixed(1),
                "totalAddedDealerNo": nationalTotalAddedDealerNo,
                "totalAddedDealerPercent": (Math.round(nationalTotalAddedDealerNo * 100) / nationalStartSum).toFixed(1),
                "endOfPeriod": nationalEndOfPeriod,
                "changedDistNo": nationalTotalChangedDistNo,
                "percentChange": (Math.round(nationalEndOfPeriod * 100) / nationalStartSum).toFixed(1)
              };
              // this.reportTempArr.push(nationalReport);

              this.nationalArr.push(nationalReport);
              nationalStartSum = 0;
              nationalJobTitleChangeAreaNo = 0;
              nationalJobTitleChangeDealerNo = 0;
              nationalTerminatedAreaNo = 0;
              nationalTerminatedDealerNo = 0;
              nationalTotalLeavingAreaNo = 0;
              nationalTotalLeavingDealerNo = 0;
              nationalDlrtoDlrChangeNo = 0;
              nationalTotalAddedAreaNo = 0;
              nationalTotalAddedDealerNo = 0;
              nationalTotalChangedDistNo = 0;
              nationalTotalChangedAreaNo = 0;
              nationalEndOfPeriod = 0;

            }
            // this.reportArray = this.reportTempArr;
            // this.reportArray = [...this.reportArray];
            this.exportData = this.reportArray;
            this.areaReportArr = this.reportArray;
            this.tableLoading = false;
            this.showSpinner = false;
            if (this.dualJobRole.length > 0) {
              this.searchForm.get('jobRole').setValue(this.dualJobRole);
              this.searchForm.get('jobRole1').setValue('');
              this.dualJobRole = '';
            }
            this.reportDataSource = new MatTableDataSource(this.reportArray);
            setTimeout(() => {
              this.reportDataSource.sort = this.sort;
              this.reportDataSource.paginator = this.ReportPaginator;
            });
          }
          else {
            this.reportArray = [];
            this.exportData = [];
            for (let i = 0; i < responseArr.length; i++) {
              this.reportArray.push(responseArr[i]);
              this.exportData.push(responseArr[i]);
              // this.reportArray[i].totalLeavingDistNo = Number(this.reportArray[i].totalLeavingDistNo) - Number(this.reportArray[i].terminatedDistNo);
              nationalStartSum += Number(this.reportArray[i].startOfPeriod);
              nationalJobTitleChangeAreaNo += Number(this.reportArray[i].jobTitleChangeDlrNo);
              nationalJobTitleChangeDealerNo += Number(this.reportArray[i].jobTitleChangeDlrNo);
              nationalTerminatedAreaNo += Number(this.reportArray[i].terminatedDlrNo);
              nationalTerminatedDealerNo += Number(this.reportArray[i].terminatedDlrNo);
              nationalTotalLeavingAreaNo += Number(this.reportArray[i].totalLeavingDlrNo);
              nationalTotalLeavingDealerNo += Number(this.reportArray[i].totalLeavingDlrNo);
              nationalDlrtoDlrChangeNo += Number(this.reportArray[i].dlrtoDlrChangeNo);
              nationalTotalAddedAreaNo += Number(this.reportArray[i].totalAddedDlrNo);
              nationalTotalAddedDealerNo += Number(this.reportArray[i].totalAddedDlrNo);
              nationalEndOfPeriod += Number(this.reportArray[i].endOfPeriod);
              if (this.reportArray[i].startOfPeriod != 0) {
                this.reportArray[i].dlrtoDlrChangePercent = (Math.round(Number(this.reportArray[i].dlrtoDlrChangeNo) * 100) / this.reportArray[i].startOfPeriod).toFixed(1)
                this.reportArray[i].jobTitleChangeDlrPercent = (Math.round(Number(this.reportArray[i].jobTitleChangeDlrNo) * 100) / this.reportArray[i].startOfPeriod).toFixed(1)
                this.reportArray[i].totalAddedDlrPercent = (Math.round(Number(this.reportArray[i].totalAddedDlrNo) * 100) / this.reportArray[i].startOfPeriod).toFixed(1)
                this.reportArray[i].terminatedDlrPercent = (Math.round(Number(this.reportArray[i].terminatedDlrNo) * 100) / this.reportArray[i].startOfPeriod).toFixed(1)
                this.reportArray[i].totalLeavingDlrPercent = (Math.round(Number(this.reportArray[i].totalLeavingDlrNo) * 100) / this.reportArray[i].startOfPeriod).toFixed(1)
                
              }
              else {
                this.reportArray[i].dlrtoDlrChangePercent = 0;
                this.reportArray[i].jobTitleChangeDlrPercent = 0;
                this.reportArray[i].totalAddedDlrPercent = 0;
                this.reportArray[i].terminatedDlrPercent = 0;
                this.reportArray[i].totalLeavingDlrPercent = 0;
                
              }
            }
            let nationalReport = {
              "timePeriod": this.reportArray[0].timePeriod,
              "jobRole": this.reportArray[0].jobRole,
              "dealerName": this.searchForm.get('area').value,
              "startOfPeriod": nationalStartSum,
              "jobTitleChangeDlrNo": nationalJobTitleChangeAreaNo,
              "jobTitleChangeDlrPercent": (Math.round(nationalJobTitleChangeAreaNo * 100) / nationalStartSum).toFixed(1),
              "jobTitleChangeDealerNo": nationalJobTitleChangeDealerNo,
              "jobTitleChangeDealerPercent": (Math.round(nationalJobTitleChangeDealerNo * 100) / nationalStartSum).toFixed(1),
              "terminatedDlrNo": nationalTerminatedAreaNo,
              "terminatedDlrPercent": (Math.round(nationalTerminatedAreaNo * 100) / nationalStartSum).toFixed(1),
              "terminatedDealerNo": nationalTerminatedDealerNo,
              "terminatedDealerPercent": (Math.round(nationalTerminatedDealerNo * 100) / nationalStartSum).toFixed(1),
              "totalLeavingDlrNo": nationalTotalLeavingAreaNo,
              "totalLeavingDlrPercent": (Math.round(nationalTotalLeavingAreaNo * 100) / nationalStartSum).toFixed(1),
              "totalLeavingDealerNo": nationalTotalLeavingDealerNo,
              "totalLeavingDealerPercent": (Math.round(nationalTotalLeavingDealerNo * 100) / nationalStartSum).toFixed(1),
              "dlrtoDlrChangeNo": nationalDlrtoDlrChangeNo,
              "dlrtoDlrChangePercent": (Math.round(nationalDlrtoDlrChangeNo * 100) / nationalStartSum).toFixed(1),
              "totalAddedDlrNo": nationalTotalAddedAreaNo,
              "totalAddedDlrPercent": (Math.round(nationalTotalAddedAreaNo * 100) / nationalStartSum).toFixed(1),
              "totalAddedDealerNo": nationalTotalAddedDealerNo,
              "totalAddedDealerPercent": (Math.round(nationalTotalAddedDealerNo * 100) / nationalStartSum).toFixed(1),
              "endOfPeriod": nationalEndOfPeriod,
              "percentChange": (Math.round(nationalEndOfPeriod * 100) / nationalStartSum).toFixed(1)
            };
            console.log('nationalReport:::', nationalReport)
            this.reportArray.push(nationalReport);
            this.nationalArr.push(nationalReport);
            this.reportTempArr = [];
            this.reportArray.forEach(reportelement => {
              this.reportTempArr.push(reportelement);
              certResponseArr.forEach(certelement => {
                if (reportelement.dealerName == certelement.dealerName) {
                  const elementTemp = certelement;
                  const a = elementTemp.dealerName
                  elementTemp.dealerName = certelement.certStatus;
                  elementTemp.certStatus = a;
                  if (elementTemp.startOfPeriod != 0) {
                    elementTemp.dlrtoDlrChangePercent = (Math.round(Number(elementTemp.dlrtoDlrChangeNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                    elementTemp.jobTitleChangeDlrPercent = (Math.round(Number(elementTemp.jobTitleChangeDlrNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                    elementTemp.totalAddedDlrPercent = (Math.round(Number(elementTemp.totalAddedDlrNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                    elementTemp.terminatedDlrPercent = (Math.round(Number(elementTemp.terminatedDlrNo) * 100) / Number(elementTemp.startOfPeriod)).toFixed(1)
                  }
                  else {
                    elementTemp.dlrtoDlrChangePercent = 0;
                    elementTemp.jobTitleChangeDlrPercent = 0;
                    elementTemp.totalAddedDlrPercent = 0;
                    elementTemp.terminatedDlrPercent = 0;
                  }
                  this.reportTempArr.push(elementTemp)
                }
              });
            })
            console.log('reportTempArr:::', this.reportTempArr)
            const groupedByCertStatus = {};
            this.reportTempArr.forEach((element) => {
              if (element.certStatus && element.district) {
                const certStatus = element.district;
                groupedByCertStatus[certStatus] = groupedByCertStatus[certStatus] || [];
                groupedByCertStatus[certStatus].push(element);
              }
            });
            let resultArray = [];
            resultArray = Object.values(groupedByCertStatus);

            let certStatusReport = ''
            for (let i = 0; i < resultArray.length; i++) {
              resultArray[i].filter(element => {
                certStatusReport = element.district
                nationalStartSum += Number(element.startOfPeriod);
                nationalJobTitleChangeAreaNo += Number(element.jobTitleChangeDlrNo);
                nationalJobTitleChangeDealerNo += Number(element.jobTitleChangeDlrNo);
                nationalTerminatedAreaNo += Number(element.terminatedDlrNo);
                nationalTerminatedDealerNo += Number(element.terminatedDlrNo);
                nationalTotalLeavingAreaNo += Number(element.totalLeavingDlrNo);
                nationalTotalLeavingDealerNo += Number(element.totalLeavingDlrNo);
                nationalDlrtoDlrChangeNo += Number(element.dlrtoDlrChangeNo);
                nationalTotalAddedAreaNo += Number(element.totalAddedDlrNo);
                nationalTotalAddedDealerNo += Number(element.totalAddedDlrNo);
                nationalEndOfPeriod += Number(element.endOfPeriod);
              })
              let nationalReport = {
                "timePeriod": this.reportArray[0].timePeriod,
                "jobRole": this.reportArray[0].jobRole,
                "dealerName": certStatusReport,
                "startOfPeriod": nationalStartSum,
                "jobTitleChangeDlrNo": nationalJobTitleChangeAreaNo,
                "jobTitleChangeDlrPercent": (Math.round(nationalJobTitleChangeAreaNo * 100) / nationalStartSum).toFixed(1),
                "jobTitleChangeDealerNo": nationalJobTitleChangeDealerNo,
                "jobTitleChangeDealerPercent": (Math.round(nationalJobTitleChangeDealerNo * 100) / nationalStartSum).toFixed(1),
                "terminatedDlrNo": nationalTerminatedAreaNo,
                "terminatedDlrPercent": (Math.round(nationalTerminatedAreaNo * 100) / nationalStartSum).toFixed(1),
                "terminatedDealerNo": nationalTerminatedDealerNo,
                "terminatedDealerPercent": (Math.round(nationalTerminatedDealerNo * 100) / nationalStartSum).toFixed(1),
                "totalLeavingDlrNo": nationalTotalLeavingAreaNo,
                "totalLeavingDlrPercent": (Math.round(nationalTotalLeavingAreaNo * 100) / nationalStartSum).toFixed(1),
                "totalLeavingDealerNo": nationalTotalLeavingDealerNo,
                "totalLeavingDealerPercent": (Math.round(nationalTotalLeavingDealerNo * 100) / nationalStartSum).toFixed(1),
                "dlrtoDlrChangeNo": nationalDlrtoDlrChangeNo,
                "dlrtoDlrChangePercent": (Math.round(nationalDlrtoDlrChangeNo * 100) / nationalStartSum).toFixed(1),
                "totalAddedDlrNo": nationalTotalAddedAreaNo,
                "totalAddedDlrPercent": (Math.round(nationalTotalAddedAreaNo * 100) / nationalStartSum).toFixed(1),
                "totalAddedDealerNo": nationalTotalAddedDealerNo,
                "totalAddedDealerPercent": (Math.round(nationalTotalAddedDealerNo * 100) / nationalStartSum).toFixed(1),
                "endOfPeriod": nationalEndOfPeriod,
                "percentChange": (Math.round(nationalEndOfPeriod * 100) / nationalStartSum).toFixed(1)
              };
              // this.reportTempArr.push(nationalReport);

              this.nationalArr.push(nationalReport);
              nationalStartSum = 0;
              nationalJobTitleChangeAreaNo = 0;
              nationalJobTitleChangeDealerNo = 0;
              nationalTerminatedAreaNo = 0
              nationalTerminatedDealerNo = 0;
              nationalTotalLeavingAreaNo = 0
              nationalTotalLeavingDealerNo = 0
              nationalDlrtoDlrChangeNo = 0
              nationalTotalAddedAreaNo = 0
              nationalTotalAddedDealerNo = 0
              nationalEndOfPeriod = 0

            }
            // this.reportArray = this.reportTempArr;
            // this.reportArray = [...this.reportArray];
            this.exportData = this.reportArray;
            this.areaReportArr = this.reportArray;
            this.tableLoading = false;
            this.showSpinner = false;
            if (this.dualJobRole.length > 0) {
              this.searchForm.get('jobRole').setValue(this.dualJobRole);
              this.searchForm.get('jobRole1').setValue('');
              this.dualJobRole = '';
            }
            this.reportDataSource = new MatTableDataSource(this.reportArray);
            setTimeout(() => {
              this.reportDataSource.sort = this.sort;
              this.reportDataSource.paginator = this.ReportPaginator;
            });
          }

        }
        else {
          this.tableLoading = false;
          this.showSpinner = false;
          if (this.dualJobRole.length > 0) {
            this.searchForm.get('jobRole').setValue(this.dualJobRole);
            this.searchForm.get('jobRole1').setValue('');
            this.dualJobRole = '';
          }
          this.reportDataSource = new MatTableDataSource(this.reportArray);
          setTimeout(() => {
            this.reportDataSource.sort = this.sort;
            this.reportDataSource.paginator = this.ReportPaginator;
          });
        }
      }, err => {
        this.tableLoading = false;
        this.showSpinner = false;
        if (this.dualJobRole.length > 0) {
          this.searchForm.get('jobRole').setValue(this.dualJobRole);
          this.searchForm.get('jobRole1').setValue('');
          this.dualJobRole = '';
        }
        this.reportDataSource = new MatTableDataSource(this.reportArray);
        setTimeout(() => {
          this.reportDataSource.sort = this.sort;
          this.reportDataSource.paginator = this.ReportPaginator;
        });
      })
    }, err => {
      this.tableLoading = false;
      this.showSpinner = false;
      if (this.dualJobRole.length > 0) {
        this.searchForm.get('jobRole').setValue(this.dualJobRole);
        this.searchForm.get('jobRole1').setValue('');
        this.dualJobRole = '';
      }
      this.reportDataSource = new MatTableDataSource(this.reportArray);
      setTimeout(() => {
        this.reportDataSource.sort = this.sort;
        this.reportDataSource.paginator = this.ReportPaginator;
      });
    })

  }
  reset() {
    let newDate = new Date();
    let prevMonthDate = new Date(newDate.setMonth(newDate.getMonth()-1)).getMonth();
    let date = new Date();
    this.searchForm.reset({
      jobRole: '',
      jobRole1: '',
      year: this.currentYear.toString(),
      // month: this.reportMonths[date.getMonth() - 1].name,
      month: this.reportMonths[prevMonthDate].name,
      area: 'All',
      district: 'All',
      timePeriod: '',
      reportType: 'area'
    });
    this.dualJobRole = '';
    this.reportArray = [];
    this.areaReportArr = [];
    this.reportTempArr = [];
    this.nationalArr = [];
    this.exportData = []
    this.tableLoading = true;
    this.showSpinner = false;
    this.reportDataSource.sort = this.sort;
    this.reportDataSource = new MatTableDataSource(this.reportArray);
    this.reportDataSource.paginator = this.ReportPaginator;
    this.searchForm.controls['district'].disable();
  }
  download() {
    let reportExportData = [];
    let fileName = '';
    if (this.searchForm.get('area').value == 'All' && this.searchForm.get('district').value =='All') {
      this.exportData.forEach(data => {
        let obj = {
          "AREA": data.area,
          "START PERIOD #": data.startOfPeriod,
          "JOB TITLE CHANGE #": data.jobTitleChangeAreaNo,
          "JOB TITLE CHANGE %": data.jobTitleChangeAreaPercent,
          'DEALER TO DEALER CHANGE #': data.dlrtoDlrChangeNo,
          "DEALER TO DEALER CHANGE %": data.dlrtoDlrChangePercent,
          "TOTAL ADDED-AREA #": data.totalAddedAreaNo,
          "TOTAL ADDED-AREA %": data.totalAddedAreaPercent,
          "TOTAL LEAVING-AREA CHANGE": data.totalLeavingAreaNo,
          "TOTAL LEAVING-TERMINATED #": data.terminatedAreaNo,
          "TOTAL LEAVING-TERMINATED %": data.terminatedAreaPercent,
          "END PERIOD #": data.endOfPeriod
        }
        reportExportData.push(obj);
      });
      let obj = {
        "AREA": ''
      }
      reportExportData.push(obj);
      obj = {
        "AREA": 'Start Period # - Associates who are active in the area during the start of turn over period.'
      }
      reportExportData.push(obj);
      obj = {
        "AREA": 'Job Title change - Associates who changed the job role during the turn over period.'
      }
      reportExportData.push(obj);
      obj = {
        "AREA": 'Dlr to Dlr change - Associates who changed the dealer during the turn over period.'
      }
      reportExportData.push(obj);
      obj = {
        "AREA": 'Total Added - Associates who joined the area. It includes new users and active usres from other area(s).'
      }
      reportExportData.push(obj);
      obj = {
        "AREA": 'Total Leaving: Changed Area # - Associates who are active but changed the area.'
      }
      reportExportData.push(obj);
      obj = {
        "AREA": 'Total Leaving: Terminated - Associates who are inactive and terminated from Lexus.'
      }
      reportExportData.push(obj);
      obj = {
        "AREA": 'End Period # - Associates who are active in the area during the end of turn over period.'
      }
      reportExportData.push(obj);
      fileName = 'Area_Turnover_Report'
    }
    else if (this.searchForm.get('area').value != 'All' && this.searchForm.get('district').value =='All'){
      this.exportData.forEach(data => {
        let obj = {
          "DISTRICT": data.district,
          "START PERIOD #": data.startOfPeriod,
          "JOB TITLE CHANGE #": data.jobTitleChangeDistNo,
          "JOB TITLE CHANGE %": data.jobTitleChangeDistPercent,
          'DEALER TO DEALER CHANGE #': data.dlrtoDlrChangeNo,
          "DEALER TO DEALER CHANGE %": data.dlrtoDlrChangePercent,
          "TOTAL ADDED-DISTRICT #": data.totalAddedDistNo,
          "TOTAL ADDED-DISTRICT %": data.totalAddedDistPercent,
          "TOTAL LEAVING-DISTRICT CHANGE": data.totalLeavingDistNo,
          "TOTAL LEAVING-TERMINATED #": data.terminatedDistNo,
          "TOTAL LEAVING-TERMINATED %": data.terminatedDistPercent,
          "END PERIOD #": data.endOfPeriod
        }
        reportExportData.push(obj);
      });
      let obj = {
        "DISTRICT": ''
      }
      reportExportData.push(obj);
      obj = {
        "DISTRICT": 'Start Period # - Associates who are active in the district during the start of turn over period.'
      }
      reportExportData.push(obj);
      obj = {
        "DISTRICT": 'Job Title change - Associates who changed the job role during the turn over period.'
      }
      reportExportData.push(obj);
      obj = {
        "DISTRICT": 'Dlr to Dlr change - Associates who changed the dealer during the turn over period.'
      }
      reportExportData.push(obj);
      obj = {
        "DISTRICT": 'Total Added - Associates who joined the district. It includes new users and active usres from other district(s).'
      }
      reportExportData.push(obj);
      obj = {
        "DISTRICT": 'Total Leaving: Changed District # - Associates who are active but changed the district.'
      }
      reportExportData.push(obj);
      obj = {
        "DISTRICT": 'Total Leaving: Terminated - Associates who are inactive and terminated from Lexus.'
      }
      reportExportData.push(obj);
      obj = {
        "DISTRICT": 'End Period # - Associates who are active in the district during the end of turn over period.'
      }
      reportExportData.push(obj);
      fileName = this.searchForm.get('area').value + '_Turnover_Report'
    }
    else {
      this.exportData.forEach(data => {
        let obj = {
          "DEALER NAME": data.dealerName,
          "START PERIOD #": data.startOfPeriod,
          "JOB TITLE CHANGE #": data.jobTitleChangeDlrNo,
          "JOB TITLE CHANGE %": data.jobTitleChangeDlrPercent,
          'DEALER TO DEALER CHANGE #': data.dlrtoDlrChangeNo,
          "DEALER TO DEALER CHANGE %": data.dlrtoDlrChangePercent,
          "TOTAL ADDED-DEALER #": data.totalAddedDlrNo,
          "TOTAL ADDED-DEALER %": data.totalAddedDlrPercent,
          "TOTAL LEAVING-DEALER CHANGE": data.totalLeavingDlrNo,
          "TOTAL LEAVING-TERMINATED #": data.terminatedDlrNo,
          "TOTAL LEAVING-TERMINATED %": data.terminatedDlrPercent,
          "END PERIOD #": data.endOfPeriod
        }
        reportExportData.push(obj);
      });
      let obj = {
        "DEALER": ''
      }
      reportExportData.push(obj);
      obj = {
        "DEALER": 'Start Period # - Associates who are active in the Dealer during the start of turn over period.'
      }
      reportExportData.push(obj);
      obj = {
        "DEALER": 'Job Title change - Associates who changed the job role during the turn over period.'
      }
      reportExportData.push(obj);
      obj = {
        "DEALER": 'Dlr to Dlr change - Associates who changed the dealer during the turn over period.'
      }
      reportExportData.push(obj);
      obj = {
        "DEALER": 'Total Added - Associates who joined the Dealer. It includes new users and active usres from other Dealer(s).'
      }
      reportExportData.push(obj);
      obj = {
        "DEALER": 'Total Leaving: Changed Dealer # - Associates who are active but changed the Dealer.'
      }
      reportExportData.push(obj);
      obj = {
        "DEALER": 'Total Leaving: Terminated - Associates who are inactive and terminated from Lexus.'
      }
      reportExportData.push(obj);
      obj = {
        "DEALER": 'End Period # - Associates who are active in the Dealer during the end of turn over period.'
      }
      reportExportData.push(obj);
      fileName = this.searchForm.get('district').value + '_Turnover_Report'
    }
    console.log('reportExportData', reportExportData, fileName)
    this.excelService.exportAsExcelFile(reportExportData, fileName);
  }
  //  ================================= TABLE SEARCH FILTER METHOD ======================================
  applyFilter(filterValue: string) {
    this.exportData = [];
    if (filterValue == "") {
      this.filterSelected = false;
    } else {
      this.filterSelected = true;
    }
    this.reportDataSource.filter = filterValue.trim().toLowerCase();
    this.reportDataSource.filteredData.forEach(element => {
      this.exportData.push(element);
    })
    // if (filterValue != '' || this.reportDataSource.filteredData.length < 10) {
    //   $("#holdHistorTable").removeClass('tableOverflow');
    // } else {
    //   $("#holdHistorTable").addClass('tableOverflow');
    // }
  }
  historyButtonClick(type: string) {
    if (type == 'viewHistory') {
      this.showHistory = true;
      this.searchForm.reset({
        jobRole: '',
        jobRole1: '',
        year: '',
        month: 'December',
        area: 'All',
        district: 'All',
        timePeriod: ''
      });
    }
    else {
      this.showHistory = false;
      let date = new Date();
      this.searchForm.reset({
        jobRole: '',
        jobRole1: '',
        year: this.currentYear.toString(),
        month: this.reportMonths[date.getMonth() - 1].name,
        area: 'All',
        district: 'All',
        timePeriod: ''
      });
    }
    this.reportArray = [];
    this.exportData = [];
    this.areaReportArr = [];
    this.reportTempArr = [];
    this.nationalArr = [];
    this.tableLoading = true;
    this.showSpinner = false;
    this.reportDataSource.sort = this.sort;
    this.reportDataSource = new MatTableDataSource(this.reportArray);
    this.reportDataSource.paginator = this.ReportPaginator;
  }
  tabClick(tab) {
    this.selectedIndex = tab.index;
    this.reportArray = [];
    if (this.selectedIndex == 1) {
      this.reportArray = this.reportTempArr;
      this.reportArray = [...this.reportArray];
      this.exportData = this.reportArray;
      this.reportDataSource = new MatTableDataSource(this.reportArray);
      setTimeout(() => {
        this.reportDataSource.sort = this.sort;
        this.reportDataSource.paginator = this.ReportPaginator;
      });
    }
    else if (this.selectedIndex == 0) {
      this.reportArray = this.areaReportArr;
      this.reportArray = [...this.reportArray];
      this.exportData = this.reportArray;
      this.reportDataSource = new MatTableDataSource(this.reportArray);
      setTimeout(() => {
        this.reportDataSource.sort = this.sort;
        this.reportDataSource.paginator = this.ReportPaginator;
      });
    }
    else if (this.selectedIndex == 2) {
      this.reportArray = this.nationalArr;
      this.reportArray = [...this.reportArray];
      this.exportData = this.reportArray;
      this.reportDataSource = new MatTableDataSource(this.reportArray);
      setTimeout(() => {
        this.reportDataSource.sort = this.sort;
        this.reportDataSource.paginator = this.ReportPaginator;
      });
    }
  }
  areaChange() {
    if (this.searchForm.get('area').value != 'All') {
      this.searchForm.controls['district'].enable();
    }
    else if (this.searchForm.get('area').value == 'All') {
      this.searchForm.controls['district'].disable();
      this.searchForm.get('district').setValue('All')
    }
  }
}
