import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { CommonService } from '../service/common.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';

let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  // 'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class SearchAssociateService {

  staffMasterAPIUrl = "";

  constructor(private http: Http, private appService: CommonService) { }

  // ==========================Get the Asociate Detail from Staff Master API ===================================
  getAssociateDetails(uid) {
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "UID": uid,
    }

    //DEV URL
    if (this.appService.baseUrl == 'https://lmldevwebapp.azurewebsites.net/api/' || this.appService.baseUrl == 'https://lmldevwebapp.azurewebsites.net/api/') {

      this.staffMasterAPIUrl = "";

    } 
    // QA and Stage URL
    // else if (this.appService.baseUrl == 'https://lmlqafunction.azurewebsites.net/api/' || this.appService.baseUrl == 'https://lmlstagefunction.azurewebsites.net/api/') {

    //   this.staffMasterAPIUrl = "";

    // } 
    // // PROD URL
    // else if (this.appService.baseUrl == 'https://lmlprodfunction.azurewebsites.net/api/') {

    //   this.staffMasterAPIUrl = "";

    // }

    if(this.staffMasterAPIUrl != ""){
      return this.http.post(this.staffMasterAPIUrl, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
    }
  }

  // ======================== Exception Handler =================================
  private handleError(error: Response | any) {
      return Observable.throw(new Error(error.status));
  }
}
